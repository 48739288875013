<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu
              :menus="menus"
              :menuActive="menuActive"
              @menu-change="menuChange"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="menuActive === 1">
              <analytics />
            </template>
            <template v-if="menuActive === 2">
              <pending-list />
            </template>
            <template v-if="menuActive === 3">
              <approved-list />
            </template>
            <template v-if="menuActive === 4">
              <rejected-list />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const Analytics = () => import('@/views/Admin/StudentCandidate/Analytics')
const PendingList = () => import('@/views/Admin/StudentCandidate/PendingList')
const ApprovedList = () => import('@/views/Admin/StudentCandidate/ApprovedList')
const RejectedList = () => import('@/views/Admin/StudentCandidate/RejectedList')

const menus = [
  {
    key: 1,
    menu: 'Student Analytics',
  },
  {
    key: 2,
    menu: 'Pending List',
  },
  {
    key: 3,
    menu: 'Approved List',
  },
  {
    key: 4,
    menu: 'Rejected List',
  },
]

export default {
  name: 'StudentCandidate',
  components: {
    Menu,
    Analytics,
    PendingList,
    ApprovedList,
    RejectedList,
  },
  data() {
    return {
      menus,
      menuActive: 1,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
}
</script>

<style lang="css">
</style>
